<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip
      top
      max-width="250"
      nudge-top="-15"
      transition="fade-transition"
      open-delay="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card tile v-bind="attrs" v-on="on" class="bill-card">
          <v-card-text>
            <span
              >#{{ bill.billNumber }}
              <v-icon
                class="float-right pointer ml-2"
                size="20"
                color="primary"
                @click="loadBill"
              >
                mdi-dots-vertical
              </v-icon>
            </span>
            <div class="text--primary force-single-line">
              {{ bill.memberName }}
            </div>
            <div
              class="success--text force-single-line"
              v-if="!bill.type.endsWith('delete')"
            >
              {{ getPayedText(bill.type) }}
            </div>
            <div class="error--text force-single-line" v-else>
              {{ getPayedText(bill.type) }}
            </div>
            <div class="text--primary force-single-line">
              <span v-if="!bill.type.endsWith('delete')">Billed on </span>
              <span v-else>Deleted on </span>
              {{ bill.createdAt | formatDate }}
            </div>
          </v-card-text>
        </v-card>
      </template>
      #{{ bill.billNumber }}
    </v-tooltip>
  </v-col>
</template>

<script>
import Moment from "moment";

export default {
  props: ["lg", "md", "sm", "xs", "cols", "bill", "loadBill"],
  setup() {
    return { Moment };
  },
  methods: {
    getPayedText(type) {
      switch (type) {
        case "due":
          return "Settled dues";
        case "due_delete":
          return "Deleted settled dues";
        case "membership_fee":
          return "Payed membership fees";
        case "membership_fee_delete":
          return "Deleted membership fees";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bill-card::after {
  // background-image: url("~@/assets/icons/bill-bk-icon.svg");
  bottom: 18px !important;
}
</style>
