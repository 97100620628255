<template>
  <div>
    <v-btn class="search-bar-toggle d-md-none"
      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
    >
    <v-sheet
      :class="[
        'books-filters-parent',
        showFilterOnXs && 'show',
        showFilterOnXs ? 'show' : ''
      ]"
      outlined
    >
      <div :class="['px-6 pt-2']" style="height: 50px">
        <v-btn
          tile
          x-small
          @click="toggleFilterOnXs()"
          class="float-right ml-2 d-md-none mt-1"
          >Hide</v-btn
        >
        <v-btn tile x-small @click="resetFilter()" class="float-right ml-2 mt-1"
          >Reset</v-btn
        >
      </div>
      <div
        class="d-flex align-center fill-width"
        style="height: calc(100% - 50px)"
      >
        <v-row class="mx-0 px-4">
          <v-col xl="6" md="6" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-text-field
              v-model="searchKey"
              clearable
              dense
              class="mx-2"
              append-icon="mdi-magnify"
              label="Search"
              placeholder="Bill No/Member Name"
            >
            </v-text-field>
          </v-col>

          <v-col xl="6" md="6" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="orderType"
              :items="searchByTypes"
              item-text="name"
              item-value="orderType"
              label="Order by"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import store from "@/store";
import { onMounted, toRefs } from "@vue/composition-api";

export default {
  setup(props) {
    onMounted(() => {});
    return {
      ...toRefs(store.memberBills.filters)
    };
  }
};
</script>
