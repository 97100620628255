<template>
  <div style="height: 100%" class="logs">
    <MemberBillsFilters />
    <v-progress-linear v-if="isLargeLoading" indeterminate></v-progress-linear>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No bills found.
    </v-container>
    <div class="books-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container
          :class="[isEmpty ? 'd-none' : '', !loadedAll ? 'pull-up-margin' : '']"
        >
          <v-row>
            <MemberBillCard
              v-for="bill in data"
              :key="bill._id"
              :bill="bill"
              :loadBill="() => loadBill(bill._id)"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
            />
          </v-row>
        </v-container>
        <SkeltonLoader
          v-if="!loadedAll"
          cardHeight="120"
          loaderType="table-cell,text,text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useMemberBills } from "@/modules";
import MemberBillsFilters from "@/components/MemberBillsFilters.vue";
import SkeltonLoader from "@/components/SkeltonLoader.vue";
import MemberBillCard from "@/components/MemberBillCard.vue";

export default {
  setup: useMemberBills,
  components: {
    MemberBillsFilters,
    SkeltonLoader,
    MemberBillCard
  }
};
</script>
